<script>
import {mapGetters} from "vuex";
// import {safeNumberConversion} from "@/utils/tools";

export default {
  name: "HandHistoryItems",
  components: {
    HandHistoryItem: () => import(/* webpackChunkName: "hand-history-item-v2" */ '@/components/HandHistoryItem.vue'),
  },
  props: {
    roomId: Number, // = game_sn
  },
  computed: {
    ...mapGetters('user', ['gameHistory']),
    // 게임 별 데이터
    items() {
      return this.gameHistory.filter(({game_sn}) => game_sn === this.roomId);
    },
    gameType(){
      return this.items[0].game_type;
    },
    gameTitle() {
      return this.items[0].game_title;
    },
    smallBlind() {
      return this.items[0].small_blind;
    },
    bigBlind() {
      return this.items[0].big_blind;
    },
    firstGameStartedAt() {
      return Math.min(...this.items.map(({starting_time}) => starting_time));
    },
    firstGameStartedTime() {
      const datetime = new Date(this.firstGameStartedAt);
      const h = datetime.getHours();
      const m = datetime.getMinutes();
      const s = datetime.getSeconds();

      return [h, m, s].map(t => t < 10 ? '0' + t : t).join(':');
    },
    sumNetProfit() {
      return this.items.reduce((sum, item) => sum + parseInt(item.net_profit), 0);
    },
    rank(){
      return this.items.find(item => item.prize_rank > 0)?.prize_rank || 0;
    },
    prize(){
      return this.items.find(item => item.prize_amount > 0)?.prize_amount || 0;
    },
  }
}
</script>

<template>
  <HandHistoryItem
    :game-type="gameType"
    :game-title="gameTitle"
    :small-blind="smallBlind"
    :big-blind="bigBlind"
    :play-count="items.length"
    :first-play-time="firstGameStartedTime"
    :balance-change="sumNetProfit"
    :rank="rank"
    :prize="prize"
    @modal="$emit('modal', {
      viewId: 'handhistorydetails',
      data: { roomId }
    })"
  />
</template>

<style scoped lang="scss">

</style>